import axios from 'axios';
import React, { useState, useEffect } from 'react';
interface house {
  uid: string;
  name: string;
  btc: number;
  eth: number;
  ltc: number;
  bch: number;
  usdt: number;
  trx: number;
  doge: number;
  ada: number;
  xrp: number;
  bnb: number;
  usdp: number;
  nexo: number;
  mkr: number;
  tusd: number;
  usdc: number;
  busd: number;
  nc: number;
  inr: number;
  shib: number;
  matic: number;
}


const Wallet: React.FC = () => {
 
  const [balances, setBalances] = useState<Record<string, string>>({});
  const [debitValues, setDebitValues] = useState<Record<string, string>>({});
  const [creditValues, setCreditValues] = useState<Record<string, string>>({});
    const [searchTerm, setSearchTerm] = useState(''); // State for the search term
    const [transferDetails, setTransferDetails] = useState<house[]>([]);
    const [selectedCurrency, setSelectedCurrency] = useState<Record<string, string>>({});
    const currencies = ['btc', 'eth', 'ltc', 'bch', 'usdt', 'trx', 'doge', 'ada', 'xrp', 'bnb', 'usdp', 'nexo', 'mkr', 'tusd', 'usdc', 'busd', 'nc', 'inr', 'shib', 'matic'];
    useEffect(()=>{
      fetchTransferDetails()
 },[])

 const handleCurrencyChange = (uid: string, currency: string) => {
  setSelectedCurrency(prev => ({ ...prev, [uid]: currency }));
};

// Function to get the balance for the selected currency

const fetchTransferDetails = async () => {
 try {
   // Fetching transfer details initiated by the user
   const response = await fetch(`https://api.betopulse.com/getwallet`);
   if (!response.ok) {
     throw new Error(`HTTP error! status: ${response.status}`);
   }
   const data = await response.json();
   
     setTransferDetails(data);


 } catch (error) {
   console.error(`Error fetching transfer details:`, error);
   // Clear the state in case of an error
   setTransferDetails([]);
 }
};

const handleDebit = async (uid: string, coin: string, debitAmount: string) => {
  const user = transferDetails.find((u) => u.uid === uid);
  if (!user) {
    console.error('User not found');
    return;
  }
  const currentBalance = parseFloat(`${user[coin as keyof house] || '0'}`);
  const amountToDebit = parseFloat(debitAmount);
  const newBalance = Math.max(0, currentBalance - amountToDebit); // Ensure balance doesn't go negative

  try {
    const response = await axios.post('https://api.betopulse.com/updatebalance', {
      uid,
      coin,
      amount: newBalance.toString(), // New balance after debit
    });

    const updatedUserIndex = transferDetails.findIndex(user => user.uid === uid);
    if (updatedUserIndex !== -1) {
      // Create a new array to ensure state updates correctly
      const updatedTransferDetails = [...transferDetails];
      updatedTransferDetails[updatedUserIndex] = {
        ...updatedTransferDetails[updatedUserIndex],
        [coin]: newBalance, // Update the balance for the specific coin
      };
      setTransferDetails(updatedTransferDetails); // Update state with the new array
    }

    // Reset the debit input field for this user
    setDebitValues(prevValues => ({
      ...prevValues,
      [uid]: '',
    }));
  } catch (error) {
    console.error('Error updating wallet:', error);
  }
};

const handleCredit = async (uid: string, coin: string, creditAmount: string) => {
  const user = transferDetails.find((u) => u.uid === uid);
  if (!user) {
    console.error('User not found');
    return;
  }
  const currentBalance = parseFloat(`${user[coin as keyof house] || '0'}`);
  // Get current balance for the selected coin
  const amountToCredit = parseFloat(creditAmount);
  const newBalance = currentBalance + amountToCredit; // Add credit amount to current balance

  try {
    const response = await axios.post('https://api.betopulse.com/updatebalance', {
      uid,
      coin,
      amount: newBalance.toString(), // New balance after credit
    });

    const updatedUserIndex = transferDetails.findIndex(user => user.uid === uid);
    if (updatedUserIndex !== -1) {
      // Create a new array to ensure state updates correctly
      const updatedTransferDetails = [...transferDetails];
      updatedTransferDetails[updatedUserIndex] = {
        ...updatedTransferDetails[updatedUserIndex],
        [coin]: newBalance, // Update the balance for the specific coin
      };
      setTransferDetails(updatedTransferDetails); // Update state with the new array
    }

    // Reset the credit input field for this user
    setCreditValues(prevValues => ({
      ...prevValues,
      [uid]: '',
    }));
  } catch (error) {
    console.error('Error updating wallet:', error);
  }
};

const handleInputChange = (uid: string | number, value: string | number, type: 'debit' | 'credit') => {
  const uidStr = String(uid); // Convert uid to string
  const valueStr = String(value); // Convert value to string

  if (type === 'debit') {
    setDebitValues(prevValues => ({
      ...prevValues,
      [uidStr]: valueStr,
    }));
  } else if (type === 'credit') {
    setCreditValues(prevValues => ({
      ...prevValues,
      [uidStr]: valueStr,
    }));
  }
};


    // Function to handle search term changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter users based on the search term
  const filteredUsers = transferDetails.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.uid.toString().includes(searchTerm)
  );
  
  return (
    <div className='ml-0 lg:ml-[256px] p-2'>

<div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or UID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
        />
      </div>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">S.No</th>
              <th scope="col" className="py-3 px-6">Name</th>
              <th scope="col" className="py-3 px-6">UID</th>
              <th scope="col" className="py-3 px-6">Coin</th>
              <th scope="col" className="py-3 px-6">Balance</th>
              <th scope="col" className="py-3 px-6">Update Balance</th>
              
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.uid} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{index + 1}</td>
                <td className="py-4 px-6">{user.name}</td>
                <td className="py-4 px-6">{user.uid}</td>
                <td>
                <select value={selectedCurrency[user.uid] || 'btc'} onChange={(e) => handleCurrencyChange(user.uid, e.target.value)}>
                  {currencies.map(currency => (
                    <option key={currency} value={currency}>{currency.toUpperCase()}</option>
                  ))}
                </select>
              </td>
              <td>
                {user[selectedCurrency[user.uid] as keyof house] || user.btc}
              </td>
                <td className="py-4 px-6 flex items-center">
  <input
    type="text"
    value={String(debitValues[user.uid] || '')} 
    onChange={(e) => handleInputChange(String(user.uid), e.target.value, 'debit')} 
    style={{
      marginRight: '8px', // Add space between input and button
      border: '1px solid #ccc', // Add border for visibility
    }}
    className="text-field-class"
  />
  <button
    onClick={() => handleDebit(user.uid, selectedCurrency[user.uid] || 'btc', debitValues[user.uid] || '0')}
    style={{ 
      backgroundColor: 'red', 
      color: 'white',
      padding: '8px 16px', // Add padding to the text inside the button
      borderRadius: '8px', // Round the corners of the button
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
      border: 'none', // Remove the default button border
    }}
    className="update-button-class"
  >
    Debit
  </button>
</td>
<td className="py-2 px-6 flex items-center">
  <input
    type="text"
    value={String(creditValues[user.uid] || '')} // Convert the credit value to a string
    onChange={(e) => handleInputChange(String(user.uid), e.target.value, 'credit')} 
    style={{
      marginRight: '8px', // Add space between input and button
      border: '1px solid #ccc', // Add border for visibility
    }}
    className="text-field-class"
  />
  <button
   onClick={() => handleCredit(user.uid, selectedCurrency[user.uid] || 'btc', creditValues[user.uid] || '0')}
    style={{ 
      backgroundColor: 'green', 
      color: 'white',
      padding: '8px 16px', // Add padding to the text inside the button
      borderRadius: '8px', // Round the corners of the button
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow to make the button appear elevated
      border: 'none', // Remove the default button border
    }}
    className="update-button-class"
  >
    Credit
  </button>
</td>


              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Wallet;
